import { useState, useEffect } from 'react';

const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const calculateOpacity = (threshold: number, sectionHeight: number) => {
    const adjustedScrollPosition = scrollPosition - sectionHeight;
    return Math.min(adjustedScrollPosition / threshold, 1);
  };

  return { scrollPosition, calculateOpacity };
};

export default useScrollPosition;
