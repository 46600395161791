import colors from '@styles/common/colors';
import devices from '@styles/common/devices';
import typography from '@styles/common/typography';
import styled from 'styled-components';

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  letter-spacing: 0.5px;
  margin: 30px 30px;

  @media (min-width: 360px) {
    margin: 30px 30px;
  }
  @media (min-width: 540px) {
    margin: 30px 80px;
  }
  @media ${devices.tablet} {
    margin: 50px 50px;
  }
  @media ${devices.laptopS} {
    margin: 50px 150px;
  }
  @media ${devices.laptopM} {
    margin: 50px 200px;
  }
  @media ${devices.laptopL} {
    margin: 50px 300px;
  }
  @media ${devices.desktop} {
    margin: 50px 650px;
  }
  @media (min-width: 2560px) {
    margin: 50px 750px;
  }
`;

export const HeaderLayout = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const LogoLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 32px;
  min-height: 50px;
`;

export const LogoSubtitle = styled.p`
  width: 70%;
  font-size: ${typography.fontSize.md};
  color: ${(props) => props.theme.text};
  letter-spacing: 1px;
  line-height: 22px;

  @media (min-width: 768px) {
    font-size: ${typography.fontSize.lg};
    line-height: 26px;
  }
`;

export const AddressLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (min-width: 768px) {
    margin-top: 12px;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-end;
  }
`;

export const AddressTitle = styled.p`
  font-size: ${typography.fontSize.lg};
  font-weight: bold;
  color: ${(props) => props.theme.text};
  letter-spacing: 1px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: ${typography.fontSize.xl};
    margin-bottom: 18px;
    margin-right: 28px;
  }
`;

export const BorderLine = styled.div`
  border: 0.1px solid ${colors.secondary_color.variant_two};
  margin: 30px 0;
`;

export const FooterLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const SocialIconLayout = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Copyright = styled.p`
  font-size: ${typography.fontSize.sm};
  color: ${(props) => props.theme.text};

  @media (min-width: 768px) {
    font-size: ${typography.fontSize.md};
  }
`;

export const IconWrapper = styled.div`
  &:hover svg {
    fill: ${colors.dark_green};
  }
`;
