import { styled } from 'styled-components';
import { IoIosArrowDropup } from 'react-icons/io';

export const MovingUpChevron = styled(IoIosArrowDropup)`
  animation: moveChevron 0.8s infinite alternate;

  @keyframes moveChevron {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(5px);
    }
  }
`;
