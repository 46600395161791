export const navLinks = [
  {
    id: 1,
    label: 'Home',
    pathname: '/',
  },
  {
    id: 2,
    label: 'About',
    pathname: '/about',
  },
  {
    id: 3,
    label: 'Services',
    pathname: '/services',
  },
  {
    id: 4,
    label: 'Projects',
    pathname: '/projects',
  },
  // {
  //   id: 5,
  //   label: 'Blog',
  //   pathname: '/blog',
  // },
  {
    id: 5,
    label: 'Contact',
    pathname: '/contact',
  },
];

export const navLinksMobile = [
  {
    id: 1,
    label: 'Home',
    pathname: '/',
  },
  {
    id: 2,
    label: 'About',
    pathname: '/about',
  },
  {
    id: 3,
    label: 'Services',
    pathname: '/services',
  },
  {
    id: 4,
    label: 'Projects',
    pathname: '/projects',
  },
  // {
  //   id: 5,
  //   label: 'Blog',
  //   pathname: '/blog',
  // },
  {
    id: 5,
    label: 'Contact',
    pathname: '/contact',
  },
  {
    id: 6,
    label: 'Privacy Policy',
    pathname: '/privacy',
  },
];
