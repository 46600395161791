import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import GlobalStyle from '@styles/GlobalStyle';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from '@components/ErrorFallback';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from '@context/ThemeProvider';

const Root = () => {
  return (
    <ThemeProvider>
      <React.StrictMode>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <GlobalStyle />
          <ToastContainer />
          <App />
        </ErrorBoundary>
      </React.StrictMode>
    </ThemeProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<Root />);
