import React from 'react';
import avatar from '@assets/images/avatar.webp';
import { ImageLayout } from '../About.style';
import useScrollVisibility from '@hooks/useScrollVisibility';
import { styled } from 'styled-components';
import colors from '@styles/common/colors';
import { Content, SectionTitle } from '@components/UI/Text';
import devices from '@styles/common/devices';

// const avatar = 'https://ik.imagekit.io/um5g7z0is/Portfolio/avatar.png';

const AboutHomeSectionDesktop: React.FC = () => {
  const showTitle = useScrollVisibility(1150);

  return (
    <InnerLayout>
      <ShowContentLayout $showtitle={showTitle}>
        <ImageLayout src={avatar} alt="My profile photo" loading="lazy" />
        <div>
          <SectionTitle>Who Am I?</SectionTitle>
          <Content>
            My approach to web development is fueled by a potent combination of <BoldText>determination</BoldText>,{' '}
            <BoldText>motivation</BoldText>, and a genuine <BoldText>passion</BoldText> for creating digital experiences
            that resonate. Whether I'm unraveling the mysteries of art or meticulously crafting code, my commitment to
            excellence remains unwavering.
          </Content>
          <Content>
            Join me on this exciting journey as we bridge the worlds of art and technology, transforming ideas into
            captivating digital realities. I look forward to pushing boundaries, embracing challenges, and continuously
            evolving in this ever-changing.
          </Content>
        </div>
      </ShowContentLayout>
    </InnerLayout>
  );
};

export default AboutHomeSectionDesktop;

export const BoldText = styled.strong`
  font-weight: bold;
  color: ${colors.dark_yellow};
`;

const InnerLayout = styled.div`
  width: 100%;
  background: linear-gradient(to right, black, #1f2937, #16a32a);

  @media ${devices.tablet} {
    padding: 50px 50px;
  }
  @media ${devices.laptopS} {
    padding: 50px 150px;
  }
  @media ${devices.laptopM} {
    padding: 50px 200px;
  }
  @media ${devices.laptopL} {
    padding: 70px 300px;
  }
  @media ${devices.desktop} {
    padding: 70px 650px;
  }
  @media (min-width: 2560px) {
    padding: 50px 750px;
  }
`;

interface AboutHomeLayoutProps {
  $showtitle: boolean;
}

const ShowContentLayout = styled.div<AboutHomeLayoutProps>`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  gap: 100px;
  opacity: ${(props) => (props.$showtitle ? 1 : 0)};
  transform: translateX(${(props) => (props.$showtitle ? 0 : '10%')});
  transition:
    opacity 0.5s ease-in-out,
    transform 0.5s ease-in-out;

  @media (min-width: 768px) {
    gap: 120px;
  }
`;
