import colors from '@styles/common/colors';
import typography from '@styles/common/typography';
import styled from 'styled-components';

interface ButtonProps {
  onClick: () => void;
  title: string;
}

export const ClickedButton = ({ title, onClick }: ButtonProps) => {
  return (
    <StyledButton onClick={onClick}>
      <ButtonText>{title}</ButtonText>
    </StyledButton>
  );
};

const StyledButton = styled.button`
  width: 100%;
  background: ${colors.dark_green};
  font-weight: bold;
  padding: 14px;
  border-radius: 4px;
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;

const ButtonText = styled.p`
  color: ${colors.text_white};
  font-size: ${typography.fontSize.md};
  letter-spacing: 0.5px;

  @media (min-width: 1440px) {
    font-size: ${typography.fontSize.lg};
  }
`;
