import HeaderLinkMobile from '@components/Header/HeaderLinkMobile';
import { useOnClickOutside } from '@hooks/useOnClickOutside';
import colors from '@styles/common/colors';
import shadows from '@styles/common/shadows';
import React, { useRef, useState } from 'react';
import { FiMenu } from 'react-icons/fi';
import { MdOutlineClose } from 'react-icons/md';
import styled from 'styled-components';
import logo from '@assets/logos/TP.svg';
import { BsFillSunFill, BsMoonStarsFill } from 'react-icons/bs';
import sizes from '@styles/common/sizes';
import { useTheme } from '@context/ThemeProvider';

const MenuDrawer: React.FC = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const drawerRef = useRef<HTMLDivElement>(null);
  const { toggleTheme, isDarkMode } = useTheme();

  const handleMenuClick = () => {
    setShowMenu(!showMenu);
    document.body.style.overflow = 'hidden';
  };

  const handleCloseMenu = () => {
    setShowMenu(false);
    document.body.style.overflow = 'unset';
  };

  useOnClickOutside(drawerRef, handleCloseMenu);

  return (
    <div ref={drawerRef}>
      <Overlay $showmenu={showMenu} onClick={handleCloseMenu} />
      <IconLayout>
        <FiMenu size={25} color={colors.secondary_color.regular} onClick={handleMenuClick} />
      </IconLayout>
      <Menu $showmenu={showMenu}>
        <DrawerIconLayout>
          <img src={logo} width={60} height={40} alt="My Logo" loading="lazy"></img>
          <IconLayout>
            <MdOutlineClose size={25} color={colors.secondary_color.regular} onClick={handleCloseMenu} />
          </IconLayout>
        </DrawerIconLayout>
        <BorderLine />
        <MenuInnerLayout>
          <div onClick={handleCloseMenu}>
            <HeaderLinkMobile />
          </div>
          <BorderLine />
          <ToggleIconLayout onClick={toggleTheme}>
            {isDarkMode ? (
              <DarkModeIconLayout>
                <IconLayout>
                  <BsMoonStarsFill size={20} aria-label="Moon and stars icon" />
                </IconLayout>
                <IconContent>Switch to light mode</IconContent>
              </DarkModeIconLayout>
            ) : (
              <DarkModeIconLayout>
                <IconLayout>
                  <BsFillSunFill size={20} color={colors.dark_tangerine} aria-label="Sun icon" />
                </IconLayout>
                <IconContent>Switch to dark mode</IconContent>
              </DarkModeIconLayout>
            )}
          </ToggleIconLayout>
        </MenuInnerLayout>
      </Menu>
    </div>
  );
};

export default MenuDrawer;

const IconLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.secondary_color.variant_two};
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
`;

const Menu = styled.div<{ $showmenu: boolean }>`
  position: fixed;
  top: 0;
  left: ${({ $showmenu }) => ($showmenu ? 0 : '-100%')};
  width: 100%;
  max-width: 330px;
  height: 100%;
  background-color: ${(props) => props.theme.background};
  transition: left 0.3s ease-in-out;
  box-shadow: ${shadows.base};
  z-index: 9999;
`;

const DrawerIconLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
  cursor: pointer;
`;

const MenuInnerLayout = styled.div`
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 80px;
`;

const Overlay = styled.div<{ $showmenu: boolean }>`
  display: block;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: ${({ $showmenu }) => ($showmenu ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  width: 100%;
  height: 100vh;
  z-index: 999;
  pointer-events: ${({ $showmenu }) => ($showmenu ? 'auto' : 'none')};
`;

const BorderLine = styled.div`
  border: 0.1px solid ${colors.secondary_color.variant_two};
`;

const ToggleIconLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.secondary_color.variant_two};
  padding: 10px;
  border-radius: 16px;
  cursor: pointer;
  margin: 25px 35px;
`;

const DarkModeIconLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const IconContent = styled.div`
  font-size: ${sizes.lg};
  color: ${(props) => props.theme.text};
  letter-spacing: 0.5px;
`;
