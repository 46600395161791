import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import colors from '@styles/common/colors';
import sizes from '@styles/common/sizes';
import { navLinksMobile } from '@utils/navigation-routes';

const MenuLinks: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activePathname, setActivePathname] = useState<string>(location.pathname);

  const handleLinkClick = (pathname: string) => {
    setActivePathname(pathname);
    navigate(pathname);
  };

  return (
    <>
      <Nav>
        {navLinksMobile.map((item) => (
          <NavLink
            key={item.id}
            href={item.pathname}
            $isactive={activePathname === item.pathname}
            onClick={() => handleLinkClick(item.pathname)}
          >
            <NavItem>{item.label}</NavItem>
          </NavLink>
        ))}
      </Nav>
    </>
  );
};

export default MenuLinks;

type NavLinkProps = {
  $isactive: boolean;
};

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
`;

const NavLink = styled.a<NavLinkProps>`
  position: relative;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${colors.dark_green};
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -4px;
    left: 0;
    background-color: ${({ $isactive }) => ($isactive ? colors.dark_green : 'transparent')};
    transform-origin: top left;
    transform: scaleX(${({ $isactive }) => ($isactive ? '1' : '0')});
    transition: transform 500ms ease-in-out;
  }

  &:hover:before {
    transform: scaleX(1);
  }

  ${({ $isactive }) => ($isactive ? 'text-decoration: underline underline-offset(8px);' : '')}
`;

const NavItem = styled.span`
  position: relative;
  font-size: ${sizes.md};
  font-weight: medium;
  color: ${(props) => props.theme.text};
  letter-spacing: 0.5px;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -4px;
    left: 0;
    background-color: ${colors.dark_green};
    transform-origin: top left;
    transition: all 0.5s ease-in-out;
    transform: scaleX(0);
  }

  &:hover::before {
    transform: scaleX(1);
  }

  @media (min-width: 768px) {
    font-size: ${sizes.lg};
  }
`;
