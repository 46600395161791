import React from 'react';
import styled from 'styled-components';
import Lottie from 'react-lottie-player';
import Scrolldown from '@assets/animations/scroll-down-animation.json';

interface ScrollDownContainerProps {
  $visible: boolean;
}

const ScrollDownAnimation: React.FC<ScrollDownContainerProps> = ({ $visible }) => {
  return (
    <ScrollDownContainer $visible={$visible}>
      <Lottie loop animationData={Scrolldown} play style={{ width: 60, height: 60 }} role="presentation" />
    </ScrollDownContainer>
  );
};

export default ScrollDownAnimation;

const ScrollDownContainer = styled.div<ScrollDownContainerProps>`
  position: absolute;
  right: 5px;
  bottom: 10px;
  transition: opacity 0.5s ease-in-out;
  opacity: ${(props) => (props.$visible ? 1 : 0)};
  padding-top: 15px;

  @media (min-width: 768px) {
    display: none;
  }
  @media (min-width: 1024px) {
    display: block;
  }
  @media (min-width: 1920px) {
    display: none;
  }
`;
