import colors from '@styles/common/colors';
import React, { ReactNode } from 'react';
import { styled } from 'styled-components';

interface ExternalLinkProps {
  href: string;
  children: ReactNode;
}

const ExternalLink: React.FC<ExternalLinkProps> = ({ children, href }) => {
  return (
    <Link href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </Link>
  );
};

export default ExternalLink;

const Link = styled.a`
  cursor: pointer;

  &:hover {
    svg {
      color: ${colors.dark_green} !important;
    }
  }
`;
