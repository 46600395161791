import useWindowSize from '@hooks/useWindowSize';
import React from 'react';
import AboutHomeSectionDesktop from './AboutHomeSectionDesktop';
import AboutHomeSectionMobile from './AboutHomeSectionMobile';
import { styled } from 'styled-components';

const AboutHomeSection: React.FC = () => {
  const size = useWindowSize();

  return (
    <Layout>
      {size.width && size.width >= 768 && <AboutHomeSectionDesktop />}
      {size.width && size.width < 768 && <AboutHomeSectionMobile />}
    </Layout>
  );
};

export default AboutHomeSection;

const Layout = styled.div`
  padding: 40px 0;

  @media (min-width: 390px) {
    padding: 120px 0 30px 0;
  }
  @media (min-width: 430px) {
    padding: 160px 0 50px 0;
  }
  @media (min-width: 540px) {
    padding: 160px 0 80px 0;
  }
  @media (min-width: 768px) {
    padding: 0 0 70px 0;
  }
`;
