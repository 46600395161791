import { createGlobalStyle } from 'styled-components';
import typography from './common/typography';
import colors from './common/colors';
import reset from 'styled-reset';

const GlobalStyle = createGlobalStyle`
 ${reset}
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body, html {
    padding: 0 ;
    margin: 0 ;
    overflow-x: hidden;
    overflow: scroll;
    font-family: ${typography.fonts.body};
    background-color: ${(props) => props.theme.background};
    color: ${(props) => props.theme.text};
    transition: background-color 0.3s, color 0.3s opacity 0.5s ease-in-out;
   }

   body.fade-out {
    opacity: 1;
  }

  #root {
    overflow: scroll;
  }
`;

export default GlobalStyle;

export const lightTheme = {
  background: `${colors.background}`,
  text: `${colors.text_grey}`,
};

export const darkTheme = {
  background: `${colors.dark_background}`,
  text: `${colors.text_white}`,
};
