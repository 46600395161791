import colors from '@styles/common/colors';
import devices from '@styles/common/devices';
import typography from '@styles/common/typography';
import styled from 'styled-components';

interface AboutHomeLayoutProps {
  $showtitle: boolean;
}

export const Layout = styled.div<AboutHomeLayoutProps>`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 100px 30px;
  opacity: ${(props) => (props.$showtitle ? 1 : 0)};
  transform: translateX(${(props) => (props.$showtitle ? 0 : '10%')});
  transition:
    opacity 0.5s ease-in-out,
    transform 0.5s ease-in-out;

  @media (min-width: 360px) {
    padding: 30px 30px;
  }

  @media ${devices.tablet} {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 50px 50px;
  }

  @media ${devices.laptopS} {
    padding: 100px 100px;
    gap: 50px;
  }

  @media ${devices.laptopM} {
    padding: 100px 200px;
  }

  @media ${devices.laptopL} {
    padding: 100px 300px;
  }

  @media ${devices.desktop} {
    gap: 200px;
    padding: 100px 700px;
  }
`;

export const ImageLayout = styled.img`
  margin: 0 auto;
  width: 200px;
  height: 187px;
  border-radius: 50%;

  @media ${devices.laptopS} {
    width: 240px;
    height: 227px;
  }
  @media ${devices.laptopM} {
    width: 340px;
    height: 327px;
  }
  @media ${devices.desktop} {
    width: 390px;
    height: 377px;
  }
`;

export const ContentLayout = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AboutTitle = styled.div`
  font-size: ${typography.fontSize['3xl']};
  font-weight: bold;
  color: ${colors.text_white};
  letter-spacing: 1px;
  margin-bottom: 30px;

  @media ${devices.laptopL} {
    font-size: ${typography.fontSize['4xl']};
    margin-bottom: 40px;
  }
  @media ${devices.desktop} {
    font-size: ${typography.fontSize['5xl']};
    margin-bottom: 60px;
  }
`;

export const Content = styled.p`
  font-size: ${typography.fontSize.sm};
  color: ${colors.text_white};
  line-height: 24px;
  margin: 15px 0;
  letter-spacing: 0.5px;
  line-height: 20px;

  @media ${devices.laptopM} {
    font-size: ${typography.fontSize.md};
    line-height: 24px;
  }
`;
