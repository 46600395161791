import React from 'react';
import styled from 'styled-components';
import colors from '@styles/common/colors';
import { IoIosArrowDropright } from 'react-icons/io';
import {
  BoxContainer,
  BoxInnerContainer,
  BoxInnerLayout,
  BoxLayout,
  Content,
  ContentTitle,
  CustomLink,
  IconTextLayout,
  ImageWrapper,
  Logo,
  LogoGautama,
  ProjectWrapper,
  TitleLayout,
} from '../Project.style';
import { useNavigate } from 'react-router-dom';
import { AboutTitle } from '@components/Services/components/Service.style';
import { Button } from '@components/Button/Button';
import MapImage from '@assets/images/sections/pexels-monstera-production-map.webp';
import LapagodeKhmereLogo from '@assets/logos/LP.svg';

// const MapImage = 'https://ik.imagekit.io/um5g7z0is/Portfolio/tr:w-800/pexels-lpk.png';
// const GautamaImage = 'https://ik.imagekit.io/um5g7z0is/Portfolio/tr:w-800/pexels-gautamatech.jpg';
// const GautamaLogo = 'https://ik.imagekit.io/um5g7z0is/Portfolio/tr:w-800/GautamaLogo.png';
// const LapagodeKhmereLogo = 'https://ik.imagekit.io/um5g7z0is/Portfolio/tr:w-800/Lapagodekhmere.png';

const ProjectSliderDesktop: React.FC = () => {
  const navigate = useNavigate();

  return (
    <ProjectWrapper>
      <IconTextLayout>
        <AboutTitle>Projects</AboutTitle>
      </IconTextLayout>
      <BoxLayout>
        <BoxInnerContainer>
          <BoxContainer onClick={() => navigate('/projects/la-pagode-khmere')}>
            <ImageWrapper>
              <Image src={MapImage} alt="La Pagode Khmère" loading="lazy" width="100%" height="auto" />
              <Logo src={LapagodeKhmereLogo} alt="La Pagode Khmère" loading="lazy" width="110px" height="70px" />
            </ImageWrapper>
            <BoxInnerLayout>
              <TitleLayout>
                <ContentTitle>La Pagode Khmère</ContentTitle>
                <IoIosArrowDropright size={30} color={colors.dark_green} />
              </TitleLayout>
              <Content>
                La Pagode Khmère is multilingual web plateform, dedicated to unveilling the hidden treasures of
                lesser-known Khmer Buddhist architecture and painting througout Cambodia from 1860 to 1975. Effortlessly
                discover these ancient sanctuaries by utilizing an interactive map feature, which provides detailed
                information for an enriching exploration of Cambodia's cultural heritage.
              </Content>
              <CustomLink href="/projects/la-pagode-khmere">
                <Button title="See more about La Pagode Khmère" />
              </CustomLink>
            </BoxInnerLayout>
          </BoxContainer>
          {/* <BoxContainer>
            <ImageWrapper>
              <Image
                src={GautamaImage}
                srcSet={
                  'https://ik.imagekit.io/um5g7z0is/Portfolio/tr:w-800/pexels-gautamatech.jpg 800w, https://ik.imagekit.io/um5g7z0is/Portfolio/tr:w-1200/pexels-gautamatech.jpg 1200w, https://ik.imagekit.io/um5g7z0is/Portfolio/tr:w-1600/pexels-gautamatech.jpg 1600w,'
                }
                alt="Gautama Tech image"
                loading="lazy"
                width="100%"
                height="auto"
              />
              <LogoGautama src={GautamaLogo} alt="Gautama Tech logo" loading="lazy" width="255px" height="50px" />
            </ImageWrapper>
            <BoxInnerLayout>
              <TitleLayout>
                <ContentTitle>Gautama Tech</ContentTitle>
                <IoIosArrowDropright size={30} color={colors.dark_green} />
              </TitleLayout>
              <Content>
                Gautama Tech, founded in the early months of 2023 in the Greater Paris Region, is more than a web
                agency. The agency blends technological passion with an artistic perspective. Committed to excellence,
                the agency thrives on pushing the boundaries of innovation. Gautama Tech creates a harmonious blend of
                creativity, aesthetics, and functionality, turning ideas into dynamic digital realities.
              </Content>
            </BoxInnerLayout>
          </BoxContainer> */}
        </BoxInnerContainer>
      </BoxLayout>
    </ProjectWrapper>
  );
};

export default ProjectSliderDesktop;

const Image = styled.img`
  max-width: 100%;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  object-fit: cover;
`;
