export const breakpoints = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptopS: '1024px',
  laptopM: '1280px',
  laptopL: '1440px',
  desktop: '1920px',
};

export default breakpoints;
export type Breakpoints = typeof breakpoints;
