import colors from '@styles/common/colors';
import devices from '@styles/common/devices';
import typography from '@styles/common/typography';
import styled from 'styled-components';

export interface HeaderTitleLayoutProps {
  showtitle: boolean;
}

export const ButtonLayout = styled.div`
  width: 205px;

  @media (min-width: 1440px) {
    width: 230px;
  }
`;

export const LandingLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 110px 30px;

  @media (min-width: 360px) {
    gap: 40px;
    padding: 100px 30px;
  }
  @media (min-width: 390px) {
    gap: 80px;
    padding: 180px 30px 0 30px;
  }
  @media (min-width: 540px) {
    gap: 60px;
    padding: 130px 80px 0 80px;
  }
  @media (min-width: 768px) {
    gap: 0;
    flex-direction: row-reverse;
    padding: 50px 50px;
  }
  @media ${devices.laptopS} {
    padding: 100px 150px;
  }
  @media ${devices.laptopM} {
    padding: 150px 200px;
  }
  @media ${devices.laptopL} {
    padding: 150px 300px;
  }
  @media ${devices.desktop} {
    padding: 70px 650px;
  }
  @media (min-width: 2560px) {
    padding: 50px 750px;
  }
`;

export const LottieLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 250px;

  @media (min-width: 390px) {
    width: 300px;
    height: 300px;
  }
  @media (min-width: 768px) {
    width: 450px;
    height: 450px;
  }
  @media (min-width: 1280px) {
    width: 550px;
    height: 550px;
  }
  @media (min-width: 1440px) {
    width: 600px;
    height: 600px;
  }
`;

export const HeaderContentLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeaderTitleLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (min-width: 1440px) {
    gap: 20px;
  }
`;

export const AuthorName = styled.h1`
  font-size: ${typography.fontSize['3xl']};
  color: ${colors.dark_green};
  letter-spacing: 1px;

  @media (min-width: 1024px) {
    font-size: ${typography.fontSize['4xl']};
  }

  @media (min-width: 1280px) {
    font-size: ${typography.fontSize['5xl']};
  }

  @media (min-width: 1920px) {
    font-size: ${typography.fontSize['6xl']};
  }
`;

export const HeaderTitle = styled.h2`
  font-size: ${typography.fontSize['2xl']};
  color: ${(props) => props.theme.text};
  letter-spacing: 1px;
  line-height: 28px;

  @media (min-width: 1280px) {
    font-size: ${typography.fontSize['3xl']};
  }

  @media (min-width: 1440px) {
    font-size: ${typography.fontSize['4xl']};
  }
`;

export const HeaderSubTitle = styled.h3`
  width: 100%;
  font-size: ${typography.fontSize.md};
  color: ${(props) => props.theme.text};
  letter-spacing: 1px;
  line-height: 20px;

  @media (min-width: 540px) {
    font-size: ${typography.fontSize.lg};
    line-height: 22px;
    width: 80%;
  }

  @media (min-width: 1440px) {
    font-size: ${typography.fontSize['2xl']};
    line-height: 28px;
  }
`;
