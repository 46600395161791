import React, { useEffect, useState } from 'react';
import logo from '@assets/logos/TP.svg';
import styled from 'styled-components';
import MenuDrawer from '@components/Drawer/MenuDrawer';
import { Link } from 'react-router-dom';
import shadows from '@styles/common/shadows';

const HeaderMobile: React.FC = () => {
  const [isSticky, setSticky] = useState(false);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    setSticky(scrollPosition > 800);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <HeaderContainer $issticky={isSticky}>
      <Link to={'/'}>
        <img src={logo} width={60} height={40} alt="My Website Logo" loading="lazy" />
      </Link>
      <MenuDrawer />
    </HeaderContainer>
  );
};

export default HeaderMobile;

const HeaderContainer = styled.div<{ $issticky: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${(props) => (props.$issticky ? props.theme.background : props.theme.background)};
  box-shadow: ${(props) => (props.$issticky ? shadows.md : 'none')};
  transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease;
  z-index: 1000;
  padding: 20px 30px;

  @media (min-width: 540px) {
    padding: 30px 80px;
  }
`;
