import React, { useState } from 'react';
import styled from 'styled-components';
import colors from '@styles/common/colors';
import MapImage from '@assets/images/sections/pexels-monstera-production-map.webp';
import LapagodeKhmerLogo from '@assets/logos/LP.png';
import GautamaLogo from '@assets/logos/Gautama.png';
import GautamaImage from '@assets/images/sections/pexels-markus-spiske.webp';
import { IoIosArrowDropright } from 'react-icons/io';
import { LuChevronLeftCircle, LuChevronRightCircle } from 'react-icons/lu';
import {
  BoxContainer,
  BoxInnerLayout,
  Content,
  ContentTitle,
  CustomLink,
  Dot,
  DotContainer,
  IconStyled,
  IconTextLayout,
  IconsLayout,
  LinkLayout,
  Logo,
  LogoGautama,
  ProjectWrapper,
  RightIconsLayout,
  Slide,
  SliderContainer,
  TextLink,
  TitleLayout,
} from '../Project.style';
import { useNavigate } from 'react-router-dom';
import { AboutTitle } from '@components/Services/components/Service.style';
import { Button } from '@components/Button/Button';

interface ProjectsSliderProps {
  containers: string[];
}

const ProjectSliderMobile: React.FC<ProjectsSliderProps> = ({ containers }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  // const nextSlide = () => {
  //   setCurrentIndex((prevIndex) => {
  //     const nextIndex = prevIndex + 1;
  //     return nextIndex < containers.length ? nextIndex : 0;
  //   });
  // };

  // const prevSlide = () => {
  //   setCurrentIndex((prevIndex) => {
  //     const nextIndex = prevIndex - 1;
  //     return nextIndex >= 0 ? nextIndex : containers.length - 1;
  //   });
  // };

  // const isFirstSlide = currentIndex === 0;
  // const isLastSlide = currentIndex === containers.length - 1;

  // const handleDotClick = (index: number) => {
  //   setCurrentIndex(index);
  // };

  return (
    <ProjectWrapper>
      <IconTextLayout>
        <AboutTitle>Projects</AboutTitle>
        {/* <RightIconsLayout>
          <DotContainer>
            <Dot $active={currentIndex === 0} onClick={() => handleDotClick(0)} />
            <Dot $active={currentIndex === 1} onClick={() => handleDotClick(1)} />
          </DotContainer>
          <IconsLayout>
            <IconStyled onClick={prevSlide} style={{ pointerEvents: isFirstSlide ? 'none' : 'auto' }}>
              Disable pointer events when on the first slide
              <LuChevronLeftCircle
                size={35}
                color={isFirstSlide ? colors.secondary_color.variant_one : colors.dark_green}
                aria-label="Chevron Left Circle"
              />
            </IconStyled>
            <IconStyled
              onClick={isLastSlide ? undefined : nextSlide}
              style={{ pointerEvents: isLastSlide ? 'none' : 'auto' }}
            >
              Disable pointer events when on the last slide
              <LuChevronRightCircle
                size={35}
                color={isLastSlide ? colors.secondary_color.variant_one : colors.dark_green}
                aria-label="Chevron Right Circle"
              />
            </IconStyled>
          </IconsLayout>
        </RightIconsLayout> */}
      </IconTextLayout>

      <SliderContainer>
        <Slide style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
          <BoxLayout>
            <BoxContainer onClick={() => navigate('/projects/la-pagode-khmere')}>
              <Image src={MapImage} alt="La Pagode Khmère" loading="lazy" width="100%" height="auto" />
              <Logo src={LapagodeKhmerLogo} alt="La Pagode Khmère" loading="lazy" width="105px" height="80px" />
              <BoxInnerLayout>
                <TitleLayout>
                  <ContentTitle>La Pagode Khmère</ContentTitle>
                  <IoIosArrowDropright size={30} color={colors.dark_green} aria-label="Arrow Drop Right" />
                </TitleLayout>
                <Content>
                  La Pagode Khmère is multilingual web plateform, dedicated to unveilling the hidden treasures of
                  lesser-known Khmer Buddhist architecture and painting througout Cambodia from 1860 to 1975.
                  Effortlessly discover these ancient sanctuaries by utilizing an interactive map feature, which
                  provides detailed information for an enriching exploration of Cambodia's cultural heritage.
                </Content>
                <CustomLink href="/projects/la-pagode-khmere">
                  <Button title="See more about La Pagode Khmère" />
                </CustomLink>
              </BoxInnerLayout>
            </BoxContainer>
          </BoxLayout>
        </Slide>

        {/* <Slide style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
          <BoxContainer>
            <Image src={GautamaImage} alt="Gautama Tech image" loading="lazy" width="100%" height="auto" />
            <LogoGautama src={GautamaLogo} alt="Gautama Tech logo" loading="lazy" width="260px" height="50px" />
            <BoxInnerLayout>
              <TitleLayout>
                <ContentTitle>Gautama Tech</ContentTitle>
                <IoIosArrowDropright size={30} color={colors.dark_green} aria-label="Arrow Drop Right" />
              </TitleLayout>
              <Content>
                Gautama Tech, founded in the early months of 2023 in the Greater Paris Region, is more than a web
                agency. The agency blends technological passion with an artistic perspective. Committed to excellence,
                the agency thrives on pushing the boundaries of innovation. Gautama Tech creates a harmonious blend of
                creativity, aesthetics, and functionality, turning ideas into dynamic digital realities.
              </Content>
            </BoxInnerLayout>
          </BoxContainer>
        </Slide> */}
      </SliderContainer>
    </ProjectWrapper>
  );
};

export default ProjectSliderMobile;

const Image = styled.img`
  width: 100%;
  height: auto;
  max-width: 100%;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
`;

const BoxLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;
