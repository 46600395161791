import React from 'react';
import logo from '@assets/logos/TP.svg';
import ExternalLink from '@components/ExternalLinks';
import MenuLinks from './MenuLinks';
import {
  AddressLayout,
  AddressTitle,
  BorderLine,
  Copyright,
  FooterLayout,
  HeaderLayout,
  IconWrapper,
  Layout,
  LogoLayout,
  LogoSubtitle,
  SocialIconLayout,
} from './Footer.style';
import { FaGithub } from 'react-icons/fa';
import colors from '@styles/common/colors';
import { AiFillLinkedin } from 'react-icons/ai';

const Footer: React.FC = () => {
  const year = new Date().getFullYear();

  return (
    <Layout>
      <HeaderLayout>
        <LogoLayout>
          <img src={logo} width={60} height={40} alt="My Website Logo" loading="lazy" />
          <LogoSubtitle>Creativity, accessibility, performance, and solution are part of my daily basis.</LogoSubtitle>
          <SocialIconLayout>
            <ExternalLink href="https://github.com/Puthpiseth">
              <IconWrapper>
                <FaGithub size={25} color={colors.secondary_color.regular} aria-label="Github Link" />
              </IconWrapper>
            </ExternalLink>
            <ExternalLink href="https://www.linkedin.com/in/puthpiseth-tun/">
              <IconWrapper>
                <AiFillLinkedin size={25} color={colors.secondary_color.regular} aria-label="Linkedin Link" />
              </IconWrapper>
            </ExternalLink>
          </SocialIconLayout>
        </LogoLayout>
        <AddressLayout>
          <AddressTitle>Explore</AddressTitle>
          <MenuLinks />
        </AddressLayout>
      </HeaderLayout>
      <BorderLine />
      <FooterLayout>
        <Copyright>All rights reserved &copy; {year} TUN Puthpiseth</Copyright>
      </FooterLayout>
    </Layout>
  );
};

export default Footer;
