import colors from '@styles/common/colors';
import devices from '@styles/common/devices';
import typography from '@styles/common/typography';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Layout = styled.div`
  width: 100%;
  padding: 50px 30px;

  @media (min-width: 540px) {
    padding: 0 80px;
  }
  @media ${devices.tablet} {
    padding: 50px 50px;
  }
  @media ${devices.laptopS} {
    padding: 50px 150px;
  }
  @media ${devices.laptopM} {
    padding: 50px 200px;
  }
  @media ${devices.laptopL} {
    padding: 50px 300px;
  }
  @media ${devices.desktop} {
    padding: 50px 650px;
  }
  @media (min-width: 2560px) {
    padding: 50px 750px;
  }
`;

export const AboutTitle = styled.p`
  font-size: ${typography.fontSize['3xl']};
  font-weight: bold;
  color: ${(props) => props.theme.text};
  letter-spacing: 1px;
  padding-bottom: 15px;

  @media (min-width: 1280px) {
    font-size: ${typography.fontSize['4xl']};
  }

  @media (min-width: 1440px) {
    font-size: ${typography.fontSize['5xl']};
  }
`;

export const ServiceHomeTitle = styled.div`
  font-size: ${typography.fontSize['3xl']};
  font-weight: bold;
  color: ${(props) => props.theme.text};
  letter-spacing: 1px;
  padding-bottom: 15px;

  @media (min-width: 1440px) {
    font-size: ${typography.fontSize['4xl']};
    margin-bottom: 35px;
  }
  @media (min-width: 1920px) {
    font-size: ${typography.fontSize['5xl']};
  }
`;

export const CustomLink = styled(Link)`
  text-decoration: none;
`;

export const LinkLayout = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
`;

export const TextLink = styled.p`
  color: ${colors.dark_green};
  font-size: ${typography.fontSize.lg};
  letter-spacing: 0.5px;
`;

export const Background = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: linear-gradient(to right, black, #1f2937, #16a32a);
  padding: 30px 30px;

  @media (min-width: 540px) {
    padding: 50px 50px;
  }
  @media ${devices.laptopS} {
    padding: 50px 150px;
  }
  @media ${devices.laptopM} {
    padding: 50px 200px;
  }
  @media ${devices.laptopL} {
    padding: 70px 300px;
  }
  @media ${devices.desktop} {
    padding: 70px 650px;
  }
  @media (min-width: 2560px) {
    padding: 50px 750px;
  }
`;

export const BoxLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1280px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 36px;
  }
`;

export const BoxInnerLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const BoxTitle = styled.p`
  font-size: ${typography.fontSize.xl};
  font-weight: bold;
  color: ${colors.text_white};
  letter-spacing: 1px;
  line-height: 26px;
`;

export const TextLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const TitleLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 30px;

  @media (min-width: 1440px) {
    margin-bottom: 40px;
  }
  @media (min-width: 1920px) {
    margin-bottom: 60px;
  }
`;

export const SubTitle = styled.div`
  font-size: ${typography.fontSize['3xl']};
  font-weight: bold;
  color: ${colors.text_white};
  letter-spacing: 1px;

  @media (min-width: 1440px) {
    font-size: ${typography.fontSize['4xl']};
  }
  @media (min-width: 1920px) {
    font-size: ${typography.fontSize['5xl']};
  }
`;

export const Description = styled.p`
  font-size: ${typography.fontSize.sm};
  color: ${colors.text_white};
  letter-spacing: 0.5px;
  line-height: 22px;

  @media (min-width: 1280px) {
    font-size: ${typography.fontSize.md};
    line-height: 24px;
  }
  @media (min-width: 1440px) {
    font-size: ${typography.fontSize.lg};
    line-height: 28px;
  }
`;

export const WorkingProcessContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px 30px 100px 30px;

  @media (min-width: 540px) {
    padding: 20px 50px 100px 50px;
  }
  @media ${devices.laptopS} {
    padding: 30px 150px 120px 150px;
  }
  @media ${devices.laptopM} {
    padding: 30px 200px 120px 200px;
  }
  @media ${devices.laptopL} {
    padding: 30px 300px 120px 300px;
  }
  @media ${devices.desktop} {
    padding: 50px 650px 120px 650px;
  }
  @media (min-width: 2560px) {
    padding: 50px 750px 120px 750px;
  }
`;

export const WorkingProcessBorderLine = styled.div`
  border: 0.1px solid ${colors.secondary_color.variant_two};
`;

export const WorkingProcessTitle = styled.div`
  font-size: ${typography.fontSize['3xl']};
  font-weight: bold;
  color: ${(props) => props.theme.text};
  letter-spacing: 1px;
  line-height: 26px;
  padding: 70px 0;

  @media (min-width: 1280px) {
    font-size: ${typography.fontSize['4xl']};
  }
  @media (min-width: 1440px) {
    font-size: ${typography.fontSize['5xl']};
  }
`;
