import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const NavigateToTopPage = () => {
  const { pathname } = useLocation();

  // To ensure that each page appears at the top when navigating in a single-page application (SPA)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default NavigateToTopPage;
