import React from 'react';
import useWindowSize from '@hooks/useWindowSize';
import ProjectSliderDesktop from './ProjectSliderDesktop';
import ProjectSliderMobile from './ProjectSliderMobile';
import { images } from '@components/Projects/ProjectsData';

const ProjectSlider: React.FC = () => {
  const size = useWindowSize();

  return (
    <>
      {size.width && size.width >= 768 && <ProjectSliderDesktop />}
      {size.width && size.width < 768 && <ProjectSliderMobile containers={images} />}
    </>
  );
};

export default ProjectSlider;
