import React from 'react';
import useWindowSize from '@hooks/useWindowSize';
import LookingForMeSectionDesktop from './LookingForMeSectionDesktop';
import LookingForMeSectionMobile from './LookingForMeSectionMobile';
import { styled } from 'styled-components';

const LookingForMeSection: React.FC = () => {
  const size = useWindowSize();

  return (
    <Layout>
      {size.width && size.width >= 768 && <LookingForMeSectionDesktop />}
      {size.width && size.width < 768 && <LookingForMeSectionMobile />}
    </Layout>
  );
};

export default LookingForMeSection;

const Layout = styled.div`
  padding: 40px 0;

  @media (min-width: 540px) {
    padding: 0 0 40px 0;
  }
  @media (min-width: 768px) {
    padding: 40px 0;
  }
  @media (min-width: 1440px) {
    padding: 50px 0;
  }
`;
