import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import logo from '@assets/logos/TP.svg';
import HeaderLinkDesktop from './HeaderLinkDesktop';
import colors from '@styles/common/colors';
import typography from '@styles/common/typography';
import { useTheme } from '@context/ThemeProvider';
import { Link } from 'react-router-dom';
import shadows from '@styles/common/shadows';
import devices from '@styles/common/devices';
import SunIcon from '@assets/icons/sun.svg';
import MoonIcon from '@assets/icons/moon.svg';

const HeaderDesktop: React.FC = () => {
  const { toggleTheme, isDarkMode } = useTheme();
  const [isSticky, setSticky] = useState(false);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    setSticky(scrollPosition > 300);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <HeaderContainer $issticky={isSticky}>
      <Link to={'/'}>
        <img src={logo} width={60} height={40} alt="My Logo" loading="lazy" />
      </Link>
      <HeaderLinkDesktop />
      <RightSectionLayout>
        <IconLayout onClick={toggleTheme} title={isDarkMode ? 'Switch to light mode' : 'Switch to dark mode'}>
          {isDarkMode ? (
            <img src={MoonIcon} width={20} height={20} alt="Moon Icon" loading="lazy" />
          ) : (
            <img src={SunIcon} width={20} height={20} alt="Sun Icon" loading="lazy" />
          )}
        </IconLayout>
      </RightSectionLayout>
    </HeaderContainer>
  );
};

export default HeaderDesktop;

const HeaderContainer = styled.div<{ $issticky: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: ${(props) => (props.$issticky ? props.theme.background : props.theme.background)};
  box-shadow: ${(props) => (props.$issticky ? shadows.md : 'none')};
  transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease;
  z-index: 1000;
  padding: 30px 50px;

  @media ${devices.laptopS} {
    padding: 30px 150px;
  }
  @media ${devices.laptopM} {
    padding: 30px 200px;
  }
  @media ${devices.laptopL} {
    padding: 30px 300px;
  }
  @media ${devices.desktop} {
    padding: 30px 650px;
  }
  @media (min-width: 2560px) {
    padding: 50px 750px;
  }
`;

const RightSectionLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const IconLayout = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.secondary_color.variant_two};
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    border: 1px solid ${colors.text_grey};
    transition: border 0.3s ease;
  }

  &:hover::before {
    content: '';
    position: absolute;
    top: 100%; /* Position the triangle above the tooltip */
    left: 50%; /* Center the triangle horizontally */
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid ${colors.text_grey}; /* Triangle above the tooltip */
    z-index: 1;
  }

  &:hover::after {
    content: attr(title);
    position: absolute;
    top: 120%; /* Position the tooltip above the icon */
    left: 50%; /* Center the tooltip horizontally */
    transform: translateX(-50%);
    padding: 10px;
    background-color: ${colors.text_grey}; /* Tooltip background color */
    color: ${colors.text_white}; /* Tooltip text color */
    border-radius: 4px;
    font-size: ${typography.fontSize.sm};
    white-space: nowrap;
    opacity: 0.9; /* Adjust as needed */
    z-index: 1;
    transition: opacity 0.3s ease; /* Add a fade-in effect to the tooltip */
  }
`;
