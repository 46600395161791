import React from 'react';
import BoxContainers from './BoxContainers';
import { CustomLink, Layout, ServiceHomeTitle } from './Service.style';
import { ButtonWithIcon } from '@components/Button/ButtonWithIcon';

const ServiceHomeSection: React.FC = () => {
  return (
    <Layout>
      <ServiceHomeTitle>Services</ServiceHomeTitle>
      <BoxContainers />
      <CustomLink to="/services">
        <ButtonWithIcon title="See more about my services" />
      </CustomLink>
    </Layout>
  );
};

export default ServiceHomeSection;
