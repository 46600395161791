import React from 'react';
import styled from 'styled-components';
import ImageCardFlippedDesktop from '@components/Services/components/ImageCardFlippedDesktop';
import { imageCardsData } from '../ImageCardData';

const BoxContainers: React.FC = () => {
  return (
    <BoxLayout>
      {imageCardsData.map((card) => (
        <ImageCardFlippedDesktop
          key={card.id}
          imageUrl={card.imageUrl}
          description={card.description}
          contentTitle={card.contentTitle}
        />
      ))}
    </BoxLayout>
  );
};

export default BoxContainers;

export const BoxLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 30px 0;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1280px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const BoxContainerLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 30px;

  @media (min-width: 540px) {
    padding: 50px 50px;
  }
  @media (min-width: 1024px) {
    padding: 50px 150px;
  }
  @media (min-width: 1280px) {
    padding: 50px 200px;
  }
  @media (min-width: 1440px) {
    padding: 70px 300px;
  }
  @media (min-width: 1920px) {
    padding: 70px 650px;
  }
  @media (min-width: 2560px) {
    padding: 50px 750px;
  }
`;
