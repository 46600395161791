import colors from '@styles/common/colors';
import devices from '@styles/common/devices';
import typography from '@styles/common/typography';
import styled from 'styled-components';

export const SectionTitle = styled.div`
  font-size: ${typography.fontSize['3xl']};
  font-weight: bold;
  color: ${colors.text_white};
  letter-spacing: 1px;
  line-height: 38px;
  margin-bottom: 30px;

  @media (min-width: 1440px) {
    font-size: ${typography.fontSize['4xl']};
    line-height: 44px;
    margin-bottom: 40px;
  }
  @media (min-width: 1920px) {
    font-size: ${typography.fontSize['5xl']};
    line-height: 54px;
    margin-bottom: 60px;
  }
`;

export const ContentTitle = styled.p`
  font-size: ${typography.fontSize['2xl']};
  color: ${colors.text_white};
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 32px;
  margin-bottom: 45px;
`;

export const Content = styled.p`
  font-size: ${typography.fontSize.sm};
  color: ${colors.text_white};
  letter-spacing: 0.5px;
  line-height: 22px;
  margin-bottom: 16px;

  @media ${devices.laptopM} {
    font-size: ${typography.fontSize.md};
    line-height: 24px;
  }
  @media ${devices.laptopL} {
    font-size: ${typography.fontSize.lg};
    line-height: 28px;
  }
  @media ${devices.desktop} {
    font-size: ${typography.fontSize.xl};
    line-height: 28px;
  }
`;
