import React from 'react';
import avatar from '@assets/images/avatar.webp';
import { styled } from 'styled-components';
import useScrollVisibility from '@hooks/useScrollVisibility';
import typography from '@styles/common/typography';
import colors from '@styles/common/colors';
import { BoldText } from './AboutHomeSectionDesktop';
import { Content, SectionTitle } from '@components/UI/Text';
import { AboutTitle } from '../About.style';

const AboutHomeSectionMobile: React.FC = () => {
  const showAvatar = useScrollVisibility(200);
  const showContentLayout = useScrollVisibility(500);

  return (
    <InnerLayout>
      <ImageStyled src={avatar} alt="My profile photo" $showtitle={showAvatar} />
      <ContentLayout $showtitle={showContentLayout}>
        <SectionTitle>Who Am I?</SectionTitle>
        <Content>
          My approach to web development is fueled by a potent combination of <BoldText>determination</BoldText>,{' '}
          <BoldText>motivation</BoldText>, and a genuine <BoldText>passion</BoldText> for creating digital experiences
          that resonate. Whether I'm unraveling the mysteries of art or meticulously crafting code, my commitment to
          excellence remains unwavering.
        </Content>
        <Content>
          Join me on this exciting journey as we bridge the worlds of art and technology, transforming ideas into
          captivating digital realities. I look forward to pushing boundaries, embracing challenges, and continuously
          evolving in this ever-changing.
        </Content>
      </ContentLayout>
    </InnerLayout>
  );
};

export default AboutHomeSectionMobile;

const InnerLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  background: linear-gradient(to right, black, #1f2937, #16a32a);
  padding: 30px 30px;

  @media (min-width: 540px) {
    gap: 70px;
    padding: 50px 80px;
  }
`;

interface AboutHomeLayoutProps {
  $showtitle: boolean;
}

const ImageStyled = styled.img<AboutHomeLayoutProps>`
  width: 240px;
  height: 227px;
  border-radius: 50%;
  opacity: ${(props) => (props.$showtitle ? 0 : 1)};
  transform: translateX(${(props) => (props.$showtitle ? '10%' : 0)});
  transition:
    opacity 0.5s ease-in-out,
    transform 0.5s ease-in-out;
`;

const ContentLayout = styled.div<AboutHomeLayoutProps>`
  opacity: ${(props) => (props.$showtitle ? 0 : 1)};
  transform: translateX(${(props) => (props.$showtitle ? '10%' : 0)});
  transition:
    opacity 0.5s ease-in-out,
    transform 0.5s ease-in-out;
`;
