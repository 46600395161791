export const typography = {
  fonts: {
    title: `'Roboto', sans-serif`,
    body: `'Montserrat', sans-serif`,
  },

  fontSize: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '2rem',
    '4xl': '2.25rem',
    '5xl': '3.25rem',
    '6xl': '4rem',
    '7xl': '6rem',
  },

  letterSpacing: {
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em',
  },

  lineHeight: {
    normal: 'normal',
    shorter: '1.125rem',
    short: '1.5rem',
    base: '1.75rem',
    tall: '2rem',
    taller: '2.5rem',
  },

  fontWeight: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
};

export default typography;
export type Typography = typeof typography;
