import colors from '@styles/common/colors';
import devices from '@styles/common/devices';
import shadows from '@styles/common/shadows';
import typography from '@styles/common/typography';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ProjectWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 50px 30px;

  @media (min-width: 540px) {
    padding: 80px 80px;
  }
  @media ${devices.tablet} {
    padding: 50px 50px;
  }
  @media ${devices.laptopS} {
    padding: 50px 150px;
  }
  @media ${devices.laptopM} {
    padding: 50px 200px;
  }
  @media ${devices.laptopL} {
    padding: 50px 300px;
  }
  @media ${devices.desktop} {
    padding: 50px 650px;
  }
  @media (min-width: 2560px) {
    padding: 50px 750px;
  }
`;

export const IconTextLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const IconStyled = styled.div`
  cursor: pointer;
`;

export const IconsLayout = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const BoxInnerLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px 30px;

  @media (min-width: 1440px) {
    font-size: ${typography.fontSize['2xl']};
  }
`;

export const BoxContainer = styled.div`
  width: 100%;
  background: ${colors.white};
  box-shadow: ${shadows.base};
  border: 0.5px solid ${colors.secondary_color.variant_two};
  border-radius: 32px;
  cursor: pointer;

  @media (min-width: 768px) {
    width: 60%;
  }
  @media (min-width: 1280px) {
    width: 50%;
  }
`;

export const TitleLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContentTitle = styled.h2`
  font-size: ${typography.fontSize.xl};
  font-weight: bold;
  color: ${colors.text_grey};
  letter-spacing: 1px;

  @media (min-width: 1280px) {
    font-size: ${typography.fontSize['2xl']};
  }
`;

export const Content = styled.p`
  font-size: ${typography.fontSize.sm};
  color: ${colors.text_grey};
  letter-spacing: 0.5px;
  line-height: 22px;

  @media (min-width: 1280px) {
    font-size: ${typography.fontSize.md};
    line-height: 24px;
  }
  @media (min-width: 1440px) {
    font-size: ${typography.fontSize.lg};
    line-height: 28px;
  }
`;

export const SliderContainer = styled.div`
  display: flex;
  overflow: hidden;
`;

export const Slide = styled.div`
  flex: 0 0 100%;
  transition: transform 0.5s ease-in-out;

  @media (min-width: 768px) {
    flex: 0 0 50%;
  }

  @media (min-width: 1440px) {
    flex: 0 0 33.33%;
  }
`;

export const Logo = styled.img`
  position: absolute;
  bottom: 0;
  top: 30px;
  left: 30px;
  border-radius: 4px;
  object-fit: cover;
`;

export const LogoGautama = styled.img`
  position: absolute;
  bottom: 0;
  top: 40px;
  left: 30px;
  border-radius: 4px;
  object-fit: cover;
`;

export const RightIconsLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  padding-left: 15px;
`;

export const DotContainer = styled.div`
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
`;

export const Dot = styled.div<{ $active: boolean }>`
  width: 10px;
  height: 10px;
  background-color: ${(props) => (props.$active ? colors.dark_green : '#ccc')};
  border-radius: 50%;
  cursor: pointer;
`;

export const CustomLink = styled.a`
  text-decoration: none;
`;

export const LinkLayout = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
`;

export const TextLink = styled.span`
  color: ${colors.dark_green};
  font-size: ${typography.fontSize.lg};
  letter-spacing: 0.5px;
`;

export const BoxLayout = styled.div`
  display: flex;
  /* gap: 24px; */
`;

export const BoxInnerContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;
`;

export const ImageWrapper = styled.div`
  position: relative;
`;
