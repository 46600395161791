import useWindowSize from '@hooks/useWindowSize';
import React from 'react';
import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';

const Header: React.FC = () => {
  const size = useWindowSize();
  return (
    <>
      {size.width && size.width >= 768 && <HeaderDesktop />}
      {size.width && size.width < 768 && <HeaderMobile />}
    </>
  );
};

export default Header;
