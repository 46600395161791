import colors from '@styles/common/colors';
import typography from '@styles/common/typography';
import { IoIosArrowDropright } from 'react-icons/io';
import styled from 'styled-components';

interface ButtonProps {
  title: string;
}

export const Button = ({ title }: ButtonProps) => {
  return (
    <ButtonLayout>
      <StyledButton>
        <InnerLayout>
          <>{title}</>
        </InnerLayout>
      </StyledButton>
    </ButtonLayout>
  );
};

const ButtonLayout = styled.div`
  width: 250px;

  @media (min-width: 1280px) {
    width: 315px;
  }
`;

const StyledButton = styled.button`
  width: 100%;
  background-color: ${colors.white};
  padding: 12px;
  border-radius: 4px;
  border: 0.5px solid ${colors.dark_green};
  color: ${colors.dark_green};
  font-size: ${typography.fontSize.xs};
  font-weight: bold;
  letter-spacing: 0.5px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.dark_green};
    color: ${colors.text_white};
  }

  @media (min-width: 1280px) {
    font-size: ${typography.fontSize.md};
  }
`;

const InnerLayout = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const MovingUpChevron = styled(IoIosArrowDropright)`
  animation: moveChevron 0.8s infinite alternate;

  @keyframes moveChevron {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(5px);
    }
  }
`;
