import CustomWeb from '@assets/images/sections/pexels-custom-web-app.webp';
import SimpleWeb from '@assets/images/sections/pexels-simple-web.webp';
import ResponsiveWeb from '@assets/images/sections/pexels-responsive-website.webp';
import ConsultationWeb from '@assets/images/sections/pexels-consultation.webp';
import SEO from '@assets/images/sections/pexels-seo.webp';
import MaintenanceWeb from '@assets/images/sections/pexels-maintenance.webp';

export const imageCardsData = [
  {
    id: '1',
    imageUrl: SimpleWeb,
    description:
      'Creating clean and impactful online presences, I specialize in a range of projects, from personal portfolios to small business websites, ensuring a lasting impression.',
    contentTitle: 'Simple Websites',
  },
  {
    id: '2',
    imageUrl: CustomWeb,
    description:
      'Seeking something unique? I specialize in crafting custom web applications tailored to your specific requirements, leveraging advanced technology for a cutting-edge solution.',
    contentTitle: 'Custom Web Applications',
  },
  {
    id: '3',
    imageUrl: ResponsiveWeb,
    description:
      'Ensuring your website not only looks flawless but functions seamlessly across all devices, delivering a consistent user experience that resonates with your audience.',
    contentTitle: 'Responsive Design',
  },
  {
    id: '4',
    imageUrl: MaintenanceWeb,
    description:
      'Keep your digital presence secure and high-performing with my maintenance services, providing timely updates and incorporating the latest technologies.',
    contentTitle: 'Maintenance & Updates',
  },
  {
    id: '5',
    imageUrl: SEO,
    description:
      "Enhance your online visibility through our SEO services, employing proven strategies like keyword research and technical SEO to boost your website's search engine ranking.",
    contentTitle: 'SEO Optimization',
  },
  {
    id: '6',
    imageUrl: ConsultationWeb,
    description:
      'Not sure where to start? Schedule a consultation to discuss your ideas, challenges, and goals. I provide custom insights and recommendations for your web development project.',
    contentTitle: 'Consultation',
  },
];
