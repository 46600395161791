import { ClickedButton } from '@components/Button/ClickedButton';
import colors from '@styles/common/colors';
import typography from '@styles/common/typography';
import React from 'react';
import styled from 'styled-components';
import Lottie from 'react-lottie-player';
import NoInternetConnection from '@assets/animations/nointernet.json';

interface NoInternetConnectionPageProps {
  onRefresh: () => void;
}

const NoInternetConnectionPage: React.FC<NoInternetConnectionPageProps> = ({ onRefresh }) => {
  return (
    <Background>
      <Wrapper>
        <LottieLayout>
          <Lottie loop animationData={NoInternetConnection} play role="presentation" />
        </LottieLayout>
        <ContentLayout>
          <Title>NO INTERNET CONNECTION</Title>
          <Content>Please check your connection, then refresh the page</Content>
          <ButtonLayout>
            <ClickedButton title={'Refresh'} onClick={onRefresh} />
          </ButtonLayout>
        </ContentLayout>
      </Wrapper>
    </Background>
  );
};

export default NoInternetConnectionPage;

const ButtonLayout = styled.div`
  width: 120px;
`;

const Background = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: linear-gradient(to right, black, #1f2937, #16a32a);
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  width: 100%;
  height: 100vh;
`;

const LottieLayout = styled.div`
  width: 250px;
  height: 250px;

  @media (min-width: 540px) {
    width: 350px;
    height: 350px;
  }
`;

const ContentLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 26px;
  width: 100%;
  max-width: 550px;
`;

const Title = styled.div`
  text-align: center;
  font-size: ${typography.fontSize['2xl']};
  font-weight: bold;
  color: ${colors.text_white};
  letter-spacing: 1px;

  @media (min-width: 768px) {
    font-size: ${typography.fontSize['3xl']};
  }
`;

const Content = styled.div`
  width: 100%;
  max-width: 550px;
  text-align: center;
  font-size: ${typography.fontSize.md};
  color: ${colors.text_white};
  letter-spacing: 1px;
  line-height: 22px;

  @media (min-width: 1024px) {
    font-size: ${typography.fontSize.lg};
    line-height: 26px;
  }
`;
