import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { MovingUpChevron } from './UI/MovingUpChevron';
import colors from '@styles/common/colors';

interface ScrollDownContainerProps {
  $visible: any;
}

const Button = styled.div<ScrollDownContainerProps>`
  position: fixed;
  bottom: 10px;
  right: 10px;
  display: ${(props) => (props.$visible ? 'block' : 'none')};
  border: none;
  cursor: pointer;
`;

const ScrollToTopButton: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    setIsVisible(scrollTop > 800); // Show button when scrolled down 100px
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Button $visible={isVisible} onClick={scrollToTop}>
      <MovingUpChevron size={50} color={colors.dark_yellow} />
    </Button>
  );
};

export default ScrollToTopButton;
