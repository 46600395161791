import MapImage from '@assets/images/sections/pexels-monstera-production-map.webp';
import PortfolioImage from '@assets/images/sections/pexels-ann-h-portfolio.png';
import LapagodeKhmerLogo from '@assets/logos/LP.png';
import PortfolioLogo from '@assets/icons/TP.png';
import GautamaLogo from '@assets/logos/GautamaLogoIcon.png';
import LaPagodeKhmere from '@assets/projects/LaPagodeKhmere.png';
import GautamaTech from '@assets/projects/GautaTech.png';

export const projectsData = [
  {
    link: 'https://lapagodekhmere.com',
    imageSrc: MapImage,
    altText: 'La Pagode Khmère',
    title: 'La Pagode Khmère',
    content:
      'Seeking something unique? I specialize in crafting custom web applications tailored to your specific requirements, leveraging advanced technology for a cutting-edge solution.',
    logoSrc: LapagodeKhmerLogo,
  },
  {
    link: 'https://lapagodekhmere.com',
    imageSrc: PortfolioImage,
    altText: 'Portfolio',
    title: 'Puthpiseth TUN',
    content:
      'Seeking something unique? I specialize in crafting custom web applications tailored to your specific requirements, leveraging advanced technology for a cutting-edge solution.',
    logoSrc: PortfolioLogo,
  },
  {
    link: 'https://lapagodekhmere.com',
    imageSrc: MapImage,
    altText: 'Gautama Tech',
    title: 'Gautama Tech',
    content:
      'Seeking something unique? I specialize in crafting custom web applications tailored to your specific requirements, leveraging advanced technology for a cutting-edge solution.',
    logoSrc: GautamaLogo,
  },
  {
    link: 'https://lapagodekhmere.com',
    imageSrc: PortfolioImage,
    altText: 'Portfolio',
    title: 'Puthpiseth TUN',
    content:
      'Seeking something unique? I specialize in crafting custom web applications tailored to your specific requirements, leveraging advanced technology for a cutting-edge solution.',
    logoSrc: PortfolioLogo,
  },
  {
    link: 'https://lapagodekhmere.com',
    imageSrc: MapImage,
    altText: 'La Pagode Khmère',
    title: 'La Pagode Khmère',
    content:
      'Seeking something unique? I specialize in crafting custom web applications tailored to your specific requirements, leveraging advanced technology for a cutting-edge solution.',
    logoSrc: LapagodeKhmerLogo,
  },
];

export const images = [LaPagodeKhmere, GautamaTech];
