import colors from '@styles/common/colors';
import typography from '@styles/common/typography';
import React from 'react';
import styled from 'styled-components';

const CardContainer = styled.div`
  width: 100%;
  max-width: 100%;
  height: 500px;
  perspective: 1000px;
  cursor: pointer;
`;

const Card = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.5s ease;
  border-radius: 32px;

  ${CardContainer}:hover & {
    transform: rotateY(180deg);
  }
`;

const Front = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 32px;
`;

const Back = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  background-color: black;
  border-radius: 32px;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 32px;
`;

const Description = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  font-size: ${typography.fontSize.md};
  color: ${colors.text_white};
  line-height: 24px;
  letter-spacing: 0.5px;
  padding: 24px;
  border-radius: 32px;

  @media (min-width: 1440px) {
    font-size: ${typography.fontSize.lg};
    line-height: 28px;
  }
`;

const ContentLayout = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(1, 1, 1, 0.8);
  padding: 18px;
  border-radius: 8px;
  font-size: ${typography.fontSize.xl};
  font-weight: bold;
  color: ${colors.text_white};
  letter-spacing: 1px;
  line-height: 28px;
  text-align: center;
`;

interface ImageCardProps {
  imageUrl: string;
  description: string;
  contentTitle: string;
}

const ImageCardFlippedDesktop: React.FC<ImageCardProps> = ({ imageUrl, description, contentTitle }) => {
  return (
    <CardContainer>
      <Card>
        <Front>
          <Image src={imageUrl} alt="Card" loading="lazy" />
          <ContentLayout>{contentTitle}</ContentLayout>
        </Front>
        <Back>
          <Description>{description}</Description>
        </Back>
      </Card>
    </CardContainer>
  );
};

export default ImageCardFlippedDesktop;
