import NavigateToTopPage from '@components/NavigateToTopPage';
import Loader from '@components/UI/Loader';
import Home from '@pages/Home';
import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const About = lazy(() => import('@pages/About'));
const Contact = lazy(() => import('@pages/Contact'));
const Projects = lazy(() => import('@pages/Projects'));
const LaPagodeKhmere = lazy(() => import('@components/Projects/LaPagodeKhmere/LaPagodeKhmere'));
const Privacy = lazy(() => import('@pages/Privacy'));
const Services = lazy(() => import('@pages/Services'));
const NotFoundPage = lazy(() => import('@pages/NotFoundPage'));

export const GlobalRouter = () => {
  return (
    <React.Fragment>
      <NavigateToTopPage />
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/projects/*" element={<Projects />} />
          <Route path="/projects/la-pagode-khmere" element={<LaPagodeKhmere />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/services" element={<Services />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
    </React.Fragment>
  );
};
