import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import colors from '@styles/common/colors';
import sizes from '@styles/common/sizes';
import { navLinks } from '@utils/navigation-routes';
import typography from '@styles/common/typography';

const HeaderLinkDesktop: React.FC = () => {
  const location = useLocation();
  // const navigate = useNavigate();
  const [activePathname, setActivePathname] = useState<string>(location.pathname);

  const handleLinkClick = (pathname: string) => {
    setActivePathname(pathname);
    document.body.classList.add('fade-out'); // Add the 'fade-out' class for smooth transition
    setTimeout(() => {
      window.location.href = pathname; // Manually navigate after the fade-out transition
    }, 50); // Adjust the timeout based on your transition duration
  };

  useEffect(() => {
    // Remove 'fade-out' class on component mount
    document.body.classList.remove('fade-out');
  }, []);

  return (
    <Nav>
      {navLinks.map((item) => (
        <NavLink
          key={item.id}
          // to={item.pathname}
          href={item.pathname}
          $isactive={activePathname === item.pathname}
          onClick={() => handleLinkClick(item.pathname)}
        >
          <NavItem>{item.label}</NavItem>
        </NavLink>
      ))}
    </Nav>
  );
};

export default HeaderLinkDesktop;

type NavLinkProps = {
  $isactive: boolean;
};

const Nav = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  @media (min-width: 1024px) {
    gap: 26px;
  }
  @media (min-width: 1280px) {
    gap: 30px;
  }
`;

const NavLink = styled.a<NavLinkProps>`
  position: relative;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${colors.dark_green};
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -5px;
    left: 0;
    background-color: ${({ $isactive }) => ($isactive ? colors.dark_green : 'transparent')};
    transform-origin: top left;
    transform: scaleX(${({ $isactive }) => ($isactive ? '1' : '0')});
    transition: transform 500ms ease-in-out;
  }

  &:hover:before {
    transform: scaleX(1);
  }

  ${({ $isactive }) => ($isactive ? 'text-decoration: underline underline-offset(8px)' : '')}
`;

const NavItem = styled.span`
  position: relative;
  font-size: ${sizes.lg};
  font-weight: medium;
  color: ${(props) => props.theme.text};
  letter-spacing: 0.5px;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -4px;
    left: 0;
    background-color: ${colors.dark_green};
    transform-origin: top left;
    transition: all 0.5s ease-in-out;
    transform: scaleX(0);
  }

  &:hover::before {
    transform: scaleX(1);
  }

  @media (min-width: 1280px) {
    font-size: ${typography.fontSize.xl};
  }
`;
