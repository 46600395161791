import React from 'react';
import { styled } from 'styled-components';
import { ClickedButton } from '../Button/ClickedButton';
import { useNavigate } from 'react-router-dom';
import typography from '@styles/common/typography';
import colors from '@styles/common/colors';
import useScrollVisibility from '@hooks/useScrollVisibility';
import devices from '@styles/common/devices';

const LookingForMeSectionDesktop: React.FC = () => {
  const navigate = useNavigate();
  const showDescription = useScrollVisibility(2700);

  return (
    <Layout>
      <Background $showlookingforme={showDescription}>
        <Title>Seeking a web developer to bring your online vision to life?</Title>
        <Description>
          Look no further. I specialize in creating dynamic and visually stunning websites that leave a lasting
          impression. Let's turn your web dreams into a reality. Contact me today, and let's embark on this exciting
          journey together!
        </Description>
        <ButtonLayout>
          <ClickedButton title={'Contact Me'} onClick={() => navigate('/contact')} />
        </ButtonLayout>
      </Background>
    </Layout>
  );
};

export default LookingForMeSectionDesktop;

interface LookingForMeProps {
  $showlookingforme: boolean;
}

const Layout = styled.div`
  width: 100%;
  background: linear-gradient(to right, black, #1f2937, #16a32a);
`;

const Background = styled.div<LookingForMeProps>`
  display: flex;
  flex-direction: column;
  gap: 26px;
  opacity: ${(props) => (props.$showlookingforme ? 0 : 1)};
  transform: translateX(${(props) => (props.$showlookingforme ? '10%' : 0)});
  transition:
    opacity 0.5s ease-in-out,
    transform 0.5s ease-in-out;

  @media ${devices.tablet} {
    padding: 50px 50px;
  }
  @media ${devices.laptopS} {
    padding: 50px 150px;
  }
  @media ${devices.laptopM} {
    padding: 50px 200px;
  }
  @media ${devices.laptopL} {
    padding: 50px 300px;
  }
  @media ${devices.desktop} {
    padding: 70px 650px;
  }
  @media (min-width: 2560px) {
    padding: 50px 750px;
  }
`;

const Description = styled.div`
  font-size: ${typography.fontSize.md};
  color: ${colors.text_white};
  line-height: 24px;
  letter-spacing: 0.5px;
  line-height: 22px;

  @media (min-width: 1024px) {
    font-size: ${typography.fontSize.lg};
    line-height: 26px;
  }
`;

const ButtonLayout = styled.div`
  width: 130px;
`;

const Title = styled.div`
  font-size: ${typography.fontSize.xl};
  font-weight: bold;
  color: ${colors.text_white};
  line-height: 24px;
  letter-spacing: 0.5px;

  @media (min-width: 768px) {
    font-size: ${typography.fontSize['2xl']};
    line-height: 28px;
  }

  @media (min-width: 1920px) {
    font-size: ${typography.fontSize['3xl']};
  }
`;
