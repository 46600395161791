import React from 'react';
import { ClickedButton } from '@components/Button/ClickedButton';
import Lottie from 'react-lottie-player';
import Particles from '@assets/animations/particles.json';
import { useNavigate } from 'react-router-dom';
import {
  LandingLayout,
  LottieLayout,
  HeaderContentLayout,
  HeaderTitleLayout,
  AuthorName,
  HeaderTitle,
  HeaderSubTitle,
  ButtonLayout,
} from './Landing.style';
import SlideInAnimation from '@components/Animations/SlideInAnimation';

const LandingDesktop: React.FC = () => {
  const navigate = useNavigate();

  return (
    <SlideInAnimation>
      <LandingLayout>
        <LottieLayout>
          <Lottie loop animationData={Particles} play role="presentation" />
        </LottieLayout>
        <HeaderContentLayout>
          <HeaderTitleLayout>
            <AuthorName>TUN Puthpiseth,</AuthorName>
            <HeaderTitle>Freelance Web Developer</HeaderTitle>
            <HeaderSubTitle>
              Creativity, accessibility, performance, and solution are part of my daily basis.
            </HeaderSubTitle>
            <ButtonLayout>
              <ClickedButton title={'Learn more about me'} onClick={() => navigate('/about')} />
            </ButtonLayout>
          </HeaderTitleLayout>
        </HeaderContentLayout>
      </LandingLayout>
    </SlideInAnimation>
  );
};

export default LandingDesktop;
