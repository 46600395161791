export const sizes = {
  xs: '6px', // 6px
  sm: '10px', // 10px
  base: '12px', // 12px
  md: '14px', // 14px
  lg: '16px', // 16px
  xl: '18px', // 18px
  '2xl': '24px', // 24px
  '3xl': '32px', // 32px
  '4xl': '48px', // 48px
};

export default sizes;
export type Sizes = typeof sizes;
