import Footer from '@components/Footer/Footer';
import Landing from '@components/Landing/Landing';
import ScrollToTopButton from '@components/ScrollToTop';
import React, { useEffect, useState } from 'react';
import NoInternetConnectionPage from './NoInternetConnection';
import { styled } from 'styled-components';
import Header from '@components/Header/Header';
import LookingForMeSection from '@components/LookingForMe/LookingForMeSection';
import AboutHomeSection from '@components/About/AboutHomeSection/AboutHomeSection';
import useScrollPosition from '@hooks/useScrollPosition';
import ScrollDownAnimation from '@components/ScrollDownAnimation';
import ProjectSlider from '@components/Projects/ProjectHomeSection/ProjectSlider';
import ServiceHomeSection from '@components/Services/components/ServiceHomeSection';
import Loader from '@components/UI/Loader';
import Seo from '@components/SEO/Seo';
import avatar from '@assets/images/avatar.webp';

const Home: React.FC = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const { scrollPosition } = useScrollPosition();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    function onlineHandler() {
      setIsOnline(true);
    }

    function offlineHandler() {
      setIsOnline(false);
    }

    window.addEventListener('online', onlineHandler);
    window.addEventListener('offline', offlineHandler);

    return () => {
      window.removeEventListener('online', onlineHandler);
      window.removeEventListener('offline', offlineHandler);
    };
  }, []);

  const handleRefresh = () => {
    window.location.reload();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Simulating a delay of 2 seconds
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setIsLoading(false); // Set loading to false when data is fetched
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false); // Set loading to false even if there's an error
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures the effect runs only once on mount

  return (
    <>
      <Seo
        title={'Home' + ' | TUN Puthpiseth'}
        description={
          'Welcome to Piseth Porfolio, Freelance Web Developer with a unique blend of academic expertise and practical experience in both the arts and technology.'
        }
        type={'website'}
        name={'TUN Puthpiseth'}
        url={'https://tun-puthpiseth.com'}
        image={avatar}
      />
      <Wrapper>
        {isOnline ? (
          <>
            <Header />
            <Landing />
            <AboutHomeSection />
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <ServiceHomeSection />
                <ProjectSlider />
                <LookingForMeSection />
              </>
            )}
            <ScrollDownAnimation $visible={scrollPosition === 0} />
            <ScrollToTopButton />
            <Footer />{' '}
          </>
        ) : (
          <NoInternetConnectionPage onRefresh={handleRefresh} />
        )}
      </Wrapper>
    </>
  );
};

export default Home;

// Fix the padding and disable the scroll bar X position
const Wrapper = styled.div`
  padding: 0;
  margin: 0;
  overflow-x: hidden;
`;
