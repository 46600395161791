import React, { useState, useEffect, ReactNode } from 'react';
import styled, { keyframes } from 'styled-components';

// Keyframe for the sliding animation
const slideInAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateX(-5%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const AnimatedItem = styled.div`
  opacity: 0;
  animation: ${slideInAnimation} 0.8s ease-in-out forwards;
`;

interface SlideInWrapperProps {
  children: ReactNode;
}

const SlideInAnimation: React.FC<SlideInWrapperProps> = ({ children }) => {
  const [_, setItemsVisible] = useState<boolean>(false);

  useEffect(() => {
    // Trigger the animation after the component mounts
    setItemsVisible(true);
  }, []);

  return (
    <Container>
      <AnimatedItem>{children}</AnimatedItem>
    </Container>
  );
};

export default SlideInAnimation;
