import useWindowSize from '@hooks/useWindowSize';
import React from 'react';
import LandingDesktop from './LandingDesktop';
import LandingMobile from './LandingMobile';

const Landing: React.FC = () => {
  const size = useWindowSize();

  return (
    <>
      {size.width && size.width >= 768 && <LandingDesktop />}
      {size.width && size.width < 768 && <LandingMobile />}
    </>
  );
};

export default Landing;
