import React from 'react';
import { styled } from 'styled-components';
import { ClickedButton } from '../Button/ClickedButton';
import { useNavigate } from 'react-router-dom';
import typography from '@styles/common/typography';
import colors from '@styles/common/colors';
import useScrollVisibility from '@hooks/useScrollVisibility';

const LookingForMeSectionMobile: React.FC = () => {
  const navigate = useNavigate();
  const showDescriptionMobile = useScrollVisibility(5500);

  return (
    <Layout>
      <Background $showlookingforme={showDescriptionMobile}>
        <Title>Seeking a web developer to bring your online vision to life?</Title>
        <Description>
          Look no further. I specialize in creating dynamic and visually stunning websites that leave a lasting
          impression. Let's turn your web dreams into a reality. Contact me today, and let's embark on this exciting
          journey together!
        </Description>
        <ButtonLayout>
          <ClickedButton title={'Contact Me'} onClick={() => navigate('/contact')} />
        </ButtonLayout>
      </Background>
    </Layout>
  );
};

export default LookingForMeSectionMobile;

interface LookingForMeProps {
  $showlookingforme: boolean;
}

const Layout = styled.div`
  background: linear-gradient(to right, black, #1f2937, #16a32a);
`;

const Background = styled.div<LookingForMeProps>`
  display: flex;
  flex-direction: column;
  gap: 26px;
  opacity: ${(props) => (props.$showlookingforme ? 0 : 1)};
  transform: translateX(${(props) => (props.$showlookingforme ? '10%' : 0)});
  transition:
    opacity 0.5s ease-in-out,
    transform 0.5s ease-in-out;
  padding: 30px 30px;

  @media (min-width: 540px) {
    padding: 50px 80px;
  }
`;

const Description = styled.div`
  font-size: ${typography.fontSize.md};
  color: ${colors.text_white};
  line-height: 24px;
  letter-spacing: 0.5px;
  line-height: 22px;

  @media (min-width: 1024px) {
    font-size: ${typography.fontSize.lg};
    line-height: 28px;
  }
`;

const ButtonLayout = styled.div`
  width: 130px;
`;

const Title = styled.div`
  font-size: ${typography.fontSize.xl};
  font-weight: bold;
  color: ${colors.text_white};
  line-height: 24px;
  letter-spacing: 0.5px;
`;
