import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

interface SeoProps {
  title: string;
  description: string;
  type: string;
  name: string;
  url: string;
  image: string;
}

const Seo: React.FC<SeoProps> = ({ title, description, type, name, url, image }) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />

        {/* Facebook meta tags */}
        <meta property="og:type" content={type} />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />

        {/* Twitter meta tags */}
        <meta name="twitter:card" content={type} />
        <meta name="twitter:image" content={image} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:creator" content={name} />
        <meta name="twitter:site" content={name} />
        <meta name="twitter:description" content={description} />

        {/* Linkedin meta tags */}
        <meta property="og:type" content={type} />
        <meta property="og:site_name" content={name} />
        <meta property="og:locale" content="en_US" />
        <meta property="article:author" content={url} />
        <meta property="article:publisher" content="https://www.linkedin.com/company/98049524/admin/feed/posts/" />
      </Helmet>
    </HelmetProvider>
  );
};

export default Seo;
